// react

import React from 'react';
// application
import BlockFeatures from '../src/components/blocks/BlockFeatures';
import BlockSpace from '../src/components/blocks/BlockSpace';
import ShopPageCategory from '~/components/shop/ShopPageCategory';
import BlockSlideshow from '~/components/blocks/BlockSlideshow';

const SLIDES = [
    {
        url: 'https://business.vmotive.in',
        desktopImage: '/images/slides/slide-3.jpg',
        mobileImage: '/images/slides/slide-3-mobile.jpg',
        title: 'Are Looking for <br> Bulk Purchase?',
        details: 'Checkout our latest Business portal <br> for bulk ordering',
        buttonLabel: 'Register Now',
    },
    {
        url: '/catalog',
        desktopImage: '/images/slides/slide-2.jpg',
        mobileImage: '/images/slides/slide-2-mobile.jpg',
        title: 'Not enough <br>spare parts?',
        details:
            // eslint-disable-next-line max-len
            'We have everything you need – replacement parts, <br>performance parts, accessories, oil & fluids, <br>tools and much more...',
        buttonLabel: 'Shop Now',
    },
    {
        url: '/catalog',
        desktopImage: '/images/slides/slide-1.jpg',
        mobileImage: '/images/slides/slide-1-mobile.jpg',
        title: 'Big Choice Of <br>Wheel Tires',
        details: 'Any size and diameter, with or without spikes, <br>summer or winter, rain or snow.',
        buttonLabel: 'Shop Now',
    },
];
function Page() {
    return (
        <React.Fragment>
            <BlockSlideshow slides={SLIDES} />
            <BlockFeatures layout="top-strip" />
            <BlockSpace layout="divider-nl" />
            <ShopPageCategory layout="columns-5-full" isHomePage />

            <BlockSpace layout="before-footer" />
        </React.Fragment>
    );
}

export default Page;
