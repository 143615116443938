// react
import React, { useState } from 'react';
import { useRouter } from 'next/router';
// import { useDispatch } from 'react-redux';
// application
import url from '~/services/url';
import VehicleSelect from '~/components/shared/VehicleSelect';
import { hrefToRouterArgs } from '~/services/router';
import { IVehicle } from '~/interfaces/vehicle';

function BlockFinder() {
    const router = useRouter();
    const [vehicle, setVehicle] = useState<IVehicle | null>(null);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (!vehicle) {
            return;
        }

        router
            .push(
                ...hrefToRouterArgs(
                    url.products({
                        filters: {
                            ...vehicle,
                        },
                    })
                )
            )
            .then();
    };

    return (
        <div className="container container--max--xl">
            <form className="block-finder__form" onSubmit={onSubmit}>
                <VehicleSelect className="block-finder__select" onVehicleChange={setVehicle} />

                <button className="block-finder__button" type="submit">
                    Search
                </button>
            </form>
        </div>
    );
}

export default React.memo(BlockFinder);
