// react
import React from 'react';
// application
import {
    Fi24Hours48Svg,
    FiFreeDelivery48Svg,
    FiPaymentSecurity48Svg,
    FiTag48Svg,
} from '~/svg';

export type IBlockFeaturesLayout = 'top-strip' | 'bottom-strip';

interface Props {
    layout: IBlockFeaturesLayout;
}

function BlockFeatures(props: Props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <ul className="block-features__list">
                    <li className="block-features__item">
                        <div className="block-features__item-icon">
                            <FiFreeDelivery48Svg />
                        </div>
                        <div className="block-features__item-info">
                            <div className="block-features__item-title">
                                Free Shipping
                            </div>
                            <div className="block-features__item-subtitle">
                                For orders from ₹500
                            </div>
                        </div>
                    </li>
                    <li className="block-features__item">
                        <div className="block-features__item-icon">
                            <Fi24Hours48Svg />
                        </div>
                        <div className="block-features__item-info">
                            <div className="block-features__item-title">
                                Support 24/7
                            </div>
                            <div className="block-features__item-subtitle">
                                Call us anytime
                            </div>
                        </div>
                    </li>
                    <li className="block-features__item">
                        <div className="block-features__item-icon">
                            <FiPaymentSecurity48Svg />
                        </div>
                        <div className="block-features__item-info">
                            <div className="block-features__item-title">
                                100% Safety
                            </div>
                            <div className="block-features__item-subtitle">
                                Only secure payments
                            </div>
                        </div>
                    </li>
                    <li className="block-features__item">
                        <div className="block-features__item-icon">
                            <FiTag48Svg />
                        </div>
                        <div className="block-features__item-info">
                            <div className="block-features__item-title">
                                Hot Offers
                            </div>
                            <div className="block-features__item-subtitle">
                                Discounts up to 90%
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default React.memo(BlockFeatures);
