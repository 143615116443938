// react
import React, { useMemo } from 'react';
// third-party
import classNames from 'classnames';
// application
import AppLink from '~/components/shared/AppLink';
import BlockHeader from '~/components/blocks/BlockHeader';
import PageTitle from '~/components/shared/PageTitle';
import url from '~/services/url';
import { IShopCategoryPageLayout, IShopCategoryPageSidebarPosition } from '~/interfaces/pages';
import { usePartCategories } from '~/store/mobile-menu/mobileMenuHooks';
import { IPartCategory } from '~/store/mobile-menu/mobileMenuTypes';
import AirConditioning from '../../../public/images/categories/Air Conditioning.jpg';
import BeltChainDrive from '../../../public/images/categories/Belt & Chain Drive.jpg';
import BodyParts from '../../../public/images/categories/Body Parts.jpg';
import BrakeSystem from '../../../public/images/categories/Brake System.jpg';
import CarAccessories from '../../../public/images/categories/Car Accessories.jpg';
import ClutchSystem from '../../../public/images/categories/Clutch System.jpg';
import CoolingSystem from '../../../public/images/categories/Cooling System.jpg';
import Electrical from '../../../public/images/categories/Electrical.jpg';
import EngineParts from '../../../public/images/categories/Engine Parts.jpg';
import ExhaustSystem from '../../../public/images/categories/Exhaust System.jpg';
import Fasteners from '../../../public/images/categories/Fasteners.jpg';
import Filters from '../../../public/images/categories/Filters.jpg';
import FuelSystem from '../../../public/images/categories/Fuel System.jpg';
import GasketSeals from '../../../public/images/categories/Gasket & Seals.jpg';
import InteriorsComfortSafety from '../../../public/images/categories/Interiors Comfort & Safety.jpg';
import Lighting from '../../../public/images/categories/Lighting.jpg';
import OilsFluids from '../../../public/images/categories/Oils & Fluids.jpg';
import Steering from '../../../public/images/categories/Steering.jpg';
import Suspension from '../../../public/images/categories/Suspension.jpg';
import Transmission from '../../../public/images/categories/Transmission.jpg';
import WheelsTyre from '../../../public/images/categories/Wheels & Tyre.jpg';
import WindscreenCleaningSystem from '../../../public/images/categories/Windscreen Cleaning System.jpg';
import Image from 'next/image';

interface Props {
    layout: IShopCategoryPageLayout;
    sidebarPosition?: IShopCategoryPageSidebarPosition;
    isHomePage?: Boolean;
}

const CATEGORIES = {
    'AIR CONDITIONING': AirConditioning,
    'BELT & CHAIN DRIVE': BeltChainDrive,
    'BODY PARTS': BodyParts,
    'BRAKE SYSTEM': BrakeSystem,
    'CAR ACCESSORIES': CarAccessories,
    'CLUTCH SYSTEM': ClutchSystem,
    'COOLING SYSTEM': CoolingSystem,
    ELECTRICAL: Electrical,
    'ENGINE PARTS': EngineParts,
    'EXHAUST SYSTEM': ExhaustSystem,
    FASTENERS: Fasteners,
    FILTERS: Filters,
    'FUEL SYSTEM': FuelSystem,
    'GASKET & SEALS': GasketSeals,
    'INTERIORS COMFORT & SAFETY': InteriorsComfortSafety,
    LIGHTING: Lighting,
    'OILS & FLUIDS': OilsFluids,
    STEERING: Steering,
    SUSPENSION: Suspension,
    TRANSMISSION: Transmission,
    'WHEELS & TYRE': WheelsTyre,
    'WINDSCREEN CLEANING SYSTEM': WindscreenCleaningSystem,
};

function ShopPageCategory(props: Props) {
    const { layout, sidebarPosition = 'start', isHomePage = false } = props;

    const hasSidebar = layout.endsWith('-sidebar');

    const categories: IPartCategory[] = usePartCategories();

    const pageTitle = useMemo(() => 'Shop', [categories]);

    const breadcrumb = useMemo(
        () => [
            { title: 'Home', url: url.home() },
            { title: 'Shop', url: url.shop() },
        ],
        [categories]
    );

    let sidebar = null;

    if (hasSidebar) {
        sidebar = (
            <div className="block-split__item block-split__item-sidebar col-auto">
                {/* {categories.length > 0 && <WidgetCategoriesList categories={categories} />} */}
            </div>
        );
    }

    const subcategoriesTemplate =
        categories.length === 0 ? null : (
            <React.Fragment>
                <div className="block">
                    <div className={`categories-list categories-list--layout--${layout}`}>
                        <ul className="categories-list__body">
                            {categories.map((category) => (
                                <React.Fragment key={category.id}>
                                    <li
                                        className={classNames('categories-list__item', {
                                            'categories-list__item--has-image': category.image_url,
                                        })}
                                    >
                                        <AppLink href={url.category(category)}>
                                            <div className="image image--type--category">
                                                <div className="image__body">
                                                    <Image
                                                        className="image__tag"
                                                        layout="fill"
                                                        loading="lazy"
                                                        // @ts-ignore
                                                        src={category.image_url || CATEGORIES[category.category]}
                                                        alt={category.category}
                                                    />
                                                </div>
                                            </div>
                                            <div className="categories-list__item-name">{category.category}</div>
                                        </AppLink>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            {!isHomePage && (
                <React.Fragment>
                    <PageTitle>{pageTitle}</PageTitle>

                    <BlockHeader pageTitle={pageTitle} breadcrumb={breadcrumb} />
                </React.Fragment>
            )}

            <div
                className={classNames('block', 'block-split', {
                    'block-split--has-sidebar': hasSidebar,
                })}
            >
                <div className="container">
                    <div className="block-split__row row no-gutters">
                        {hasSidebar && sidebarPosition === 'start' && sidebar}

                        <div className="block-split__item block-split__item-content col-auto flex-grow-1">
                            {subcategoriesTemplate}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShopPageCategory;
